.sort-button{
    margin: 10px;
    font-size: 20px;
    cursor: pointer;
    
    border-radius: 10px;
    padding: 10px;
    border-style: none;
    background-color: forestgreen;
    
    color: white;
    font-weight: bold;

    transition: background-color .2s ;
}

.sort-button:hover{
    background-color: black;

}

.container{
    display: flex;
    flex-direction: row;
    height: 50vh;
    width: 50wh;
  
    margin-top: 100px;
  
    column-gap: 1px;
    justify-content: center;
    align-items: end;
  }